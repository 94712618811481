import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VRow,[_c(VSnackbar,{attrs:{"min-width":"1500px","min-height":"150px"},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c(VBtn,_vm._b({attrs:{"color":"blue","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.Text)+" ")])],1),_c('div',{staticClass:"pl-15 ml-15"},[_c(VCol,{attrs:{"cols":"6"}},[_c('br'),_c('br'),_c(VTextField,{attrs:{"label":"public","outlined":"","shaped":""},model:{value:(_vm.ppk),callback:function ($$v) {_vm.ppk=$$v},expression:"ppk"}}),_c('br'),_c(VBtn,{staticClass:"mr-4",attrs:{"color":"success"},on:{"click":function($event){return _vm.subscribe()}}},[_vm._v(" Apply ")]),_c(VBtn,{staticClass:"mr-4",attrs:{"color":"info"},on:{"click":function($event){return _vm.swRegisrter()}}},[_vm._v(" Register ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }