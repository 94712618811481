<template>
<div>
  <v-row>
    <v-snackbar
      v-model="snackbar"
      min-width="1500px"
      min-height="150px"
    >
      {{Text}}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="blue"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
    </v-row>
    <div class="pl-15 ml-15">
      <v-col cols="6">
      <br />
      <br />
      <v-text-field
        v-model="ppk"
        label="public"
        outlined
        shaped
      ></v-text-field>
      <br />
        <v-btn
      color="success"
      class="mr-4"
      @click="subscribe()"
    >
      Apply
    </v-btn>
            <v-btn
      color="info"
      class="mr-4"
      @click="swRegisrter()"
    >
      Register
    </v-btn>
    </v-col>
  </div>
  </div>
</template>
<script>
//import AppCardActions from '@core/components/app-card-actions/AppCardActions.vue'
//import { mdiDeleteOutline, mdiDotsVertical, mdiEyeOutline, mdiPencilOutline, mdiPlus, mdiTrendingUp } from '@mdi/js'
// expremintal disable template support
import { Create } from '@core/api/Chat';
import { ref } from '@vue/composition-api';
export default {

  setup() {

    const ppk = ref('')
    const snackbar = ref(false)
    const Text =ref('')
    const server = {
      P256dh: '',
      EndPoint:'',
      Auth:'',
    }
const logging = v => {
  console.log('vparse',v)
  let x = JSON.parse( JSON.stringify(v))
  console.log('xxxxxx', x.endpoint, '          keys',x.keys,'          auth',x.keys.auth)
}
     async function subscribe() {
      console.log('ppk',ppk.value )
         let sw = await navigator.serviceWorker.ready;
         console.log('sw ')
         let push = await sw.pushManager.subscribe({
           userVisibleOnly: true,
           applicationServerKey: ppk.value,
         })
         logging(push)
         let x = JSON.parse( JSON.stringify(push))
          server.Auth = x.keys.auth
          server.P256dh =  x.keys.p256dh
          server.EndPoint = x.endpoint
          console.log('server', server)
          Create(server)
          Text.value = 'pushing' + JSON.stringify(push)
          snackbar.value = true
       }
        //    if ('serviceWorker' in navigator) {
        //     console.log('if', navigator)
        //    addEventListener('load', async () => {
        //      let sw = await navigator.serviceWorker.register('./sw.js');
        //      console.log(sw);
        //    });
        //  }
      if ('serviceWorker' in navigator) {
        addEventListener('load', async () => {
          let sw = await navigator.serviceWorker.register('./sw.js')
          console.log(sw);
        });
      }
//         const swRegisrter = () => {


//         navigator.serviceWorker
//             .register("sw.js", {
//     scope: './' }).then(function (registration) {
//     var serviceWorker;
//     if (registration.installing) {
//         serviceWorker = registration.installing;
//         console.log('installing');
//     } else if (registration.waiting) {
//         serviceWorker = registration.waiting;
//         console.log('waiting');
//     } else if (registration.active) {
//         serviceWorker = registration.active;
//         console.log('active');
//     }
//     if (serviceWorker) {
//         // logState(serviceWorker.state);
//         serviceWorker.addEventListener('statechange', function (e) {
//             // logState(e.target.state);
//         });
//     }
// }).catch(err => console.log("Service wroker: error: " + err))

// }

    return {
      //beamsClient,
      swRegisrter,
      server,
      ppk,
      Text,
      snackbar,
      subscribe,
    }
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/apps/invoice.scss';

.app-invoice-editable {
  .input-salesperson {
    width: 100px;
  }

  .select-invoice-to {
    width: 190px !important;
  }

  .header-inputs {
    width: 122px;
  }

  .add-products-form {
    .single-product-form {
      &:not(:first-child) {
        margin-top: 2rem;
      }
    }

    .header-spacer {
      // This is according to item actions width
      width: 39px;
    }
    .item-actions {
      @at-root {
        @include theme--child(add-products-form) using ($material) {
          .item-actions {
            border-left: thin solid map-deep-get($material, 'dividers');
          }
        }
      }
    }
  }
}
</style>
